import React from 'react'
import {GoodDay} from '../../types/pickDay/GoodDay'
import moment from 'moment-timezone'
import {title, solarDate, lunarDate} from './pick-day.module.scss'
import {StarBox} from './star-box'
import {getLunarDateFromMoment} from '../../functions/lunar/utils'

interface Props {
  goodDay: GoodDay
}

export const GoodDayBox: React.FC<Props> = ({goodDay}) => {
  const {time} = goodDay
  const m = moment.tz(time, 'Asia/Seoul')
  const lunar = getLunarDateFromMoment(m)
  const weekday = m.format('ddd')
  const color =
    weekday === 'Sun' ? '#ff4227' : weekday === 'Sat' ? '#4c50ff' : '#1c1c1c'
  return (
    <div>
      <div className="flex items-center">
        <div>
          <div className="flex items-center mb-1">
            <h2 className="mb-0 my-0">
              {moment.tz(time, 'Asia/Seoul').format('M월 D일')}
            </h2>
            <h3 className="ml-2 my-0">
              {getDayInKr(weekday)}
            </h3>
          </div>
          <div className="text-gray-500 bold">
            음력 {parseInt(lunar.month)}월 {parseInt(lunar.day)}일{' '}
            {lunar.leapMonth && '윤달'}
          </div>
        </div>
        {/*</div>*/}
        <StarBox star={goodDay.point} />
      </div>

      {goodDay.descriptions.map((val, i) => (
        <p key={i}>{val}</p>
      ))}
      <h3>이사하면 안 좋은 방향</h3>
      <p>{goodDay.dangerDirection}</p>
      <div className="miniDivider"/>
    </div>
  )
}

function getDayInKr(weekday: string): string {
  switch (weekday) {
    case 'Mon':
      return '월요일'
    case 'Tue':
      return '화요일'
    case 'Wed':
      return '수요일'
    case 'Thu':
      return '목요일'
    case 'Fri':
      return '금요일'
    case 'Sat':
      return '토요일'
    case 'Sun':
      return '일요일'

    default:
      return ''
  }
}
