import React from 'react'
import OwnerInfoBox from '../input-box/owner-input-box'
import {CircularProgress} from '@material-ui/core'
import {GoodDay} from '../../types/pickDay/GoodDay'
import {Element} from 'react-scroll/modules'
// @ts-ignore
import AdSense from 'react-adsense'
import {GoodDayBox} from './good-day-box'
import SEO from '../seo'
import {Container, Divider} from '../../styles/common'
import SetupAnalysis from '../common/SetupAnalysis'
import Schedule from '../common/Schedule'
import SaJooAds from "../common/ads/sajoo-ads";

interface Props {
  goodDayInfos: GoodDay[]
  fetch: () => void
  isLoading: boolean
}

export const PickDayContainer: React.FC<Props> = ({
  goodDayInfos,
  fetch,
  isLoading,
}) => {
  return (
    <div>
      <SEO
        title={'무료 택일'}
        description={
          '오늘로부터 90일 동안, 이사, 행사, 창업, 잔치 등 일을 벌이기 좋은 날을 무료 택일할 수 있도록 정보를 드립니다.'
        }
      />
      <section className="pt-10 sm:pt-20 mb-40 mt-10">
        <div
          className={
            'container max-w-screen-xl mx-auto px-8 lg:flex lg:items-center'
          }
        >
          <div className="text-center  lg:w-1/2">
            <h1 className="text-5xl lg:text-6xl xl:text-6xl font-bold leading-none">
              일반 택일
            </h1>
            <h2 className="text-xl sm:text-2xl  font-semibold text-gray-500">
              일반 택일은 무료입니다.
            </h2>

            <OwnerInfoBox />
            <button
              className={
                'block mx-auto mb-2  w-64  py-3    bg-blue-400 hover:bg-blue-500 rounded text-white'
              }
              style={{width: 300}}
              onClick={() => {
                if (!isLoading) {
                  fetch()
                }
              }}
            >
              {isLoading ? (
                <CircularProgress color={'inherit'} size={20} disableShrink />
              ) : (
                '좋은 날 보기'
              )}
            </button>
          </div>
          <div className="lg:w-1/2">
            <div className="mx-auto max-w-2xl">
              <Schedule />
            </div>
          </div>
        </div>
      </section>

      <Divider hidden />
      <Container>
        <Element name={'after-load'} />
        {/*<AdSense.Google*/}
        {/*  client="ca-pub-5894919272228059"*/}
        {/*  slot="8397580173"*/}
        {/*  style={{display: 'block'}}*/}
        {/*  format="auto"*/}
        {/*  responsive="true"*/}
        {/*  layoutKey="-gw-1+2a-9x+5c"*/}
        {/*/>*/}
        <SaJooAds/>
        {goodDayInfos.length > 0 && (
          <div>
            {/*<div className={'miniDivider'} />*/}
            <p>
              꼭 손 없는 날만 좋은 날일까요? 그렇지 않습니다. 손 없는 날 뿐만이
              아니라 길일을 정하는 여러가지 방법들이 있습니다. 손 없는 날이
              아니더라도 길일은 있을 수 있습니다. 8 codes에서 알려 드립니다.
            </p>

            <p>이사는 세대주(가장) 기준으로 택일하시기 바랍니다.</p>
            <div className={'miniDivider'} />

            {goodDayInfos.slice(0, 30).map((goodDay, i) => (
              <GoodDayBox key={i} goodDay={goodDay} />
            ))}
            {/*<AdSense.Google*/}
            {/*  client="ca-pub-5894919272228059"*/}
            {/*  slot="8397580173"*/}
            {/*  style={{display: 'block'}}*/}
            {/*  format="auto"*/}
            {/*  responsive="true"*/}
            {/*  layoutKey="-gw-1+2a-9x+5c"*/}
            {/*/>*/}
            {/*<div className={'miniDivider'} />*/}

            <SaJooAds/>
            {goodDayInfos.slice(30, 60).map((goodDay, i) => (
              <GoodDayBox key={i} goodDay={goodDay} />
            ))}
            {/*<AdSense.Google*/}
              {/*  client="ca-pub-5894919272228059"*/}
              {/*  slot="8397580173"*/}
              {/*  style={{display: 'block'}}*/}
              {/*  format="auto"*/}
              {/*  responsive="true"*/}
              {/*  layoutKey="-gw-1+2a-9x+5c"*/}
              {/*/>*/}
              {/*<div className={'miniDivider'} />*/}
              <SaJooAds/>
            {goodDayInfos.slice(60, goodDayInfos.length).map((goodDay, i) => (
              <GoodDayBox key={i} goodDay={goodDay} />
            ))}
            {/*<AdSense.Google*/}
            {/*  client="ca-pub-5894919272228059"*/}
            {/*  slot="8397580173"*/}
            {/*  style={{display: 'block'}}*/}
            {/*  format="auto"*/}
            {/*  responsive="true"*/}
            {/*  layoutKey="-gw-1+2a-9x+5c"*/}
            {/*/>*/}
            <SaJooAds/>
          </div>
        )}
      </Container>
      <Divider hidden />
    </div>
  )
}
