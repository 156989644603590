import React from 'react'

interface Props {
  star: number
}

export const StarBox: React.FC<Props> = ({star}) => {
  const iconUrl = star >= 0 ? '/icons/star.svg' : '/icons/star_red.svg'
  const arr = []
  for (let i = 0; i < Math.abs(star); i++) {
    arr.push(1)
  }

  return (
    <div className="flex ml-4">
      {arr.map((val, i) => (
        <img
          className="w-6 h-6 ml-1"
          key={i}
          src={iconUrl}
        />
      ))}
    </div>
  )
}
