export enum Ji {
  JA = 11,
  CHUK,
  IN,
  MYO,

  JIN,
  SA,
  OH,
  MI,

  SIN,
  YU,
  SUL,
  HAE,
}
