import {AppState} from '../../store/AppState'
import {Dispatch} from 'redux'
import {fetchGoodDayInfos} from '../../store/pick-day/actions'
import {connect} from 'react-redux'
import {PickDayContainer} from './pick-day-container'

const mapStateToProps = ({pickDay}: AppState) => ({
  goodDayInfos: pickDay.goodDayInfos,
  isLoading: pickDay.isLoading,
})
const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetch: () => dispatch(fetchGoodDayInfos()),
})

export default connect(mapStateToProps, mapDispatchToProps)(PickDayContainer)
